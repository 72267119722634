body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  min-width: 100%;
  min-height: 100vh;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.settings {
  font-size: 24px;
  text-align: center;
}

.settings svg {
  weight: 24px;
  height: 24px;
  fill: white;
}
