@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Exo&family=Orbitron&family=Quantico&family=Wallpoet&family=Source+Code+Pro&family=Space+Grotesk&display=swap&family=Megrim&family=Cute+Font&family=Dekko&family=Train+One&family=Plaster&family=Lacquer&family=Geo&family=Silkscreen:wght@700&family=Stalinist+One&display=swap?family=Geostar+Fill");

body {
  overflow: hidden;
}

button {
  cursor: pointer;
  text-transform: capitalize;
}

.btn-label {
  font-size: 9px;
  position: relative;
  margin-top: -5px;
  margin: auto;
}

button.fssettings {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 501;
  background-color: transparent;
  color: white;
  border: none;
  width: 32px;
  opacity: 0.25;
}

button.fssettings:hover {
  opacity: 1;
}

#bgVideo {
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: brightness(200%);
  top: 0;
  left: 0;
  animation: vidfadein 5s;
  opacity: 0.25;
}

.time {
  font-size: 10em;
  color: white;
  display: block;
  position: absolute;
  margin: auto;
  padding: 0;
  top: calc(50% - 0.7em);
  left: 0;
  /* left: calc(50% - 6ch); */
  white-space: nowrap;
  min-width: fit-content;
  width: 100%;
}

.time-digits,
.meridiems {
  /* width: 8ch; */
  line-height: 1em;
  display: block;
  font-family: "Orbitron", sans-serif;
  text-align: center;
}

.date-digits {
  line-height: 1em;
  font-family: "Orbitron", sans-serif;
  font-size: 0.5em;
  text-align: center;
  visibility: visible;
}

.date-digits.off {
  visibility: hidden;
}

.grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  overflow: hidden;
}

.grid.off .grid-item {
  border: none !important;
  color: transparent !important;
}

.grid-item {
  color: #111;
  font-family: Helvetica, sans-serif;
}

#g2,
#g5,
#g8 {
  border-left: 1px solid #111;
  border-right: 1px solid #111;
}

#g4,
#g5,
#g6 {
  border-top: 1px solid #111;
  border-bottom: 1px solid #111;
}

#rings {
  opacity: 0.25;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.dialog-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 101;
  background: url(../img/dots.png);
}

.dialog {
  position: absolute;
  width: 930px;
  min-height: 500px;
  padding: 2.5rem;
  background: white;
  color: black;
  font-family: "Space Grotesk", Arial, Helvetica, sans-serif;
  font-size: 1.25em;
  font-weight: 500;
  border-radius: 7px;
  transform-origin: center center;
}

.dialog h1 {
  text-align: center;
  font-weight: 700;
}

.dialog a {
  text-transform: none;
}

.dialog .donate-button {
  width: fit-content;
  display: block;
  margin: auto;
  font-size: 1em;
  border: none;
  border-radius: 12px;
  background-color: aquamarine;
  text-align: center;
  padding: 0.5rem 1rem;
  margin-top: 2rem;
  z-index: 151;
}

.dialog .donate-button svg {
  width: 1.5rem;
  vertical-align: middle;
}

.dialog .donate-button span {
  display: inline;
  width: 30px;
  font-weight: 700;
  vertical-align: middle;
  text-transform: none;
}

.dialog .close-button {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 0.5rem;
  right: 0;
}

.dialog .close-button svg {
  width: 2rem;
}

.dialog a {
  text-decoration: none;
}

.ring1 {
  position: relative;
  display: block;
  min-width: 60rem;
  min-height: 60rem;
  border: 1px solid transparent;
  border-radius: 50%;
  animation: spin 60s linear infinite;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='60rem' ry='60rem' stroke='white' stroke-width='25' stroke-dasharray='2%2c 6' stroke-dashoffset='32' stroke-linecap='butt'/%3e%3c/svg%3e");
  box-shadow: 0 0 200px 5px white;
}

.boxy {
  margin-left: 50%;
  display: block;
  max-width: 0.1rem;
  min-height: 1.5rem;
  transform-origin: center center;
}

.ring2 {
  position: absolute;
  display: block;
  min-width: 55rem;
  min-height: 55rem;
  border: 1px solid white;
  border-radius: 50%;
  animation: spin 180s linear infinite;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='55rem' ry='55rem' stroke='white' stroke-width='25' stroke-dasharray='100%2c 200%2c 50' stroke-dashoffset='100' stroke-linecap='square'/%3e%3c/svg%3e");
  box-shadow: 0 0 20px 5px white;
}

.ring3 {
  position: absolute;
  display: block;
  min-width: 100rem;
  min-height: 100rem;
  border: 1px solid white;
  border-radius: 50%;
}

footer {
  z-index: 101;
}

footer button {
  background-color: transparent;
  border: 0;
  opacity: 0.25;
  cursor: pointer;
}

footer button:hover {
  opacity: 1;
}

#settingsOverlay {
  position: absolute;
  top: -0.5em;
  width: 100%;
  height: 0.5em;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

#settingsOverlay button {
  background-color: black;
  padding: 0.5rem 0.8rem;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 7px;
  cursor: pointer;
  color: white;
  height: fit-content;
}

.iconButton svg {
  height: 3em;
  position: inherit;
}

#settingsOverlay button.close {
  background-color: transparent;
  border: none;
  font-size: 0.06em;
}

.copyright {
  position: "absolute";
  margin-top: "-1.5em";
  color: rgba(255, 255, 255, 0.15);
}

button#DonateButton {
  display: block;
  position: absolute;
  margin-top: -3.25em;
  right: 0;
  color: white;
  z-index: 101;
}

button#DonateButton svg {
  width: 3em;
  height: 3em;
  fill: white;
}

.leaderboard {
  text-align: center;
  width: 930px;
  height: 180px;
  margin-top: 4rem;
  background-color: transparent;
  position: absolute;
  bottom: 1rem;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes vidfadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.25;
  }
}
